

const DashBoard = () => {
    return (
        <div className="dashboard-container">
            DashBoard
        </div>
    )
}

export default DashBoard;